import { ref } from 'vue';
import Intercom, { show } from '@intercom/messenger-js-sdk';
import {
  useRuntimeConfig,
  useNuxtApp,
  useNotifications,
  createError,
} from '#imports';

export function useIntercom() {
  const config = useRuntimeConfig();
  const isLoading = ref(false);
  const isLoaded = ref(false);
  const { $sentry } = useNuxtApp();
  const notification = useNotifications();
  const { uid, email, isBrand, brandUser, memberUser, isPremium, isCmUser } =
    useUserState();

  const userName = isBrand
    ? brandUser.value?.firstName
    : memberUser.value?.onboarding?.firtName;
  const userId = uid.value;
  const userEmail = email.value;
  const isBrandUser = isBrand.value;
  const memberStatus = memberUser.value?.membership;
  const isCmPremium = isPremium.value && isCmUser.value;
  const createdAt = isBrandUser
    ? (brandUser.value?.createdAtTimeMs ?? new Date().getTime()) / 1000
    : (memberUser.value?.createdAtTimeMs ?? new Date().getTime()) / 1000;

  const getUserHash = async () => {
    const { headers } = authenticatedRequestHeaders();
    try {
      const result = await $fetch('/api/intercom-verify', {
        method: 'GET',
        headers: headers,
      });

      return result;
    } catch (error: any) {
      throw createError({
        statusMessage: error?.statusMessage,
        statusCode: error?.statusCode,
      });
    }
  };

  const openIntercom = async () => {
    if (!import.meta.client) {
      return;
    }

    if (!userId) {
      return console.error('User ID is missing. Cannot open Intercom.');
    }

    isLoading.value = true;
    try {
      const userHash = await getUserHash();

      if (userId && config.public.intercomAppId) {
        const initData = {
          app_id: config.public.intercomAppId,
          user_id: userId,
          created_at: createdAt,
          user_hash: userHash,
          name: typeof userName === 'string' ? userName : undefined,
          email: userEmail || undefined,
          tn_member_status: memberStatus,
          tn_is_cm_premium: isCmPremium,
          alignment: 'right',
          horizontal_padding: 10,
        };

        Intercom(initData);
        isLoaded.value = true;

        show();
      }
    } catch (error: any) {
      $sentry.captureException(error);
      console.error(error);
      notification.showErrorNotification({
        description:
          error.statusMessage || 'Error, Intercom could not be initialized',
      });
    } finally {
      isLoading.value = false;
    }
  };

  return { isLoading, openIntercom };
}
